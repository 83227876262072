import React from "react";
import loadable from "@loadable/component";
import { connect } from "react-redux";

const ScrollableIcon = loadable(() => import("./components/ScrollableIcon"));

function SimpleContentWithMore({ id, context = [], index, breadcrumb }) {
  if (!context.length) {
    return <></>;
  }

  return (
    <section className="uk-section uk-section-default">
      <div className="uk-container uk-container-expand">
        <div data-uk-grid="">
          <div className="uk-width-1-4@s">
            <ScrollableIcon breadcrumb={breadcrumb} index={index} />
          </div>
          <div className="uk-width-expand">
            <div dangerouslySetInnerHTML={{ __html: context[0] }} />
            <button
              className={`more-${id} uk-button uk-button-link`}
              data-uk-toggle={`target: .more-${id}; animation: uk-animation-fade;`}
            >
              <i data-uk-icon="plus" /> mehr anzeigen
            </button>
            <div className={`more-${id}`} hidden>
              <div dangerouslySetInnerHTML={{ __html: context[1] }} />
              <button
                className="uk-button uk-button-link"
                data-uk-toggle={`target: .more-${id}; animation: uk-animation-fade;`}
              >
                <i data-uk-icon="minus" /> weniger anzeigen
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function mapStateToProps({ breadcrumb }) {
  return { breadcrumb };
}

export default connect(mapStateToProps)(SimpleContentWithMore);
